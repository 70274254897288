import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from "@fortawesome/free-brands-svg-icons";

import {
	animationVariantsHorizontal,
	animationVariantsVertical,
	logo,
	pageUsername,
} from "../assets";

import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';

import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';

import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Page from "../common/Page";

const PageFirst = () => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1
	});

	const handleClick = () => {
		if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
			window.location.href = `fb://page/?id=${pageUsername}`;
		} else if (window.navigator.userAgent.match(/Android/i)) {
			window.location.href = `fb://page/${pageUsername}`;
		} else {
			window.location.href = `https://www.facebook.com/${pageUsername}`;
		}
	};

	return (
		<Page>
			<header className="hidden flex-col items-center md:flex">
				<img src={logo} alt="Tâm Đức Số" className="h-24 md:h-36"/>
				<p className="font-semibold md:text-2xl">
					<p>Cộng đồng miễn phí - Phương pháp hiệu quả</p>
					<p>Chia sẻ cầu kèo - Giúp đời cũng là giúp mình</p>
				</p>
			</header>

			<div className="w-full flex flex-col justify-center px-8 md:mt-8 md:flex-row md:px-0">
				<div className="w-auto md:mt-20 md:mr-8 md:-ml-8">
					<motion.div className="w-full flex flex-col items-center md:w-auto"
					            ref={ref}
					            initial="hidden"
					            animate={inView ? "visible" : "hidden"}
					            variants={animationVariantsVertical}
					            transition={{duration: 1, ease: "easeOut"}}>

						<img src={logo} alt="Tâm Đức Số" className="mx-auto h-36 md:hidden"/>

						<h1 className="text-4xl font-bold text-[#FFC700] hero-title">Tâm Đức Số</h1>

						<p className="hidden mt-2 md:block">Trang đăng ký thành viên cộng đồng</p>

						<div className="text-center text-sm mt-8 font-semibold md:hidden">
							<p>Cộng đồng miễn phí - Phương pháp hiệu quả</p>
							<p>Chia sẻ cầu kèo - Giúp đời cũng là giúp mình</p>
						</div>
					</motion.div>

					<div className="flex-col items-center mt-8 flex">
					<motion.a href="#"
					          onClick={handleClick}
						          rel="noopener noreferrer"
						          className="w-[280px] bg-[#1877F2] text-white text-md inline-flex pl-3.5 pr-4 py-4 font-semibold rounded-md items-center space-x-3 mb-4 drop-shadow-2xl shadow-black md:text-xl"
						          ref={ref}
						          initial="hidden"
						          animate={inView ? "visible" : "hidden"}
						          variants={animationVariantsHorizontal}
						          transition={{duration: 1, ease: "easeOut"}}>
							<FontAwesomeIcon icon={fab.faFacebook}/>
							<span>Fanpage Tâm Đức Số</span>
						</motion.a>

						<motion.a href="#"
						          onClick={handleClick}
						          rel="noopener noreferrer"
						          className="w-[280px] bg-[#FF0000] text-white text-md inline-flex pl-3.5 pr-4 py-4 font-semibold rounded-md items-center space-x-3 mb-4 drop-shadow-2xl shadow-black md:text-xl"
						          ref={ref}
						          initial="hidden"
						          animate={inView ? "visible" : "hidden"}
						          variants={animationVariantsHorizontal}
						          transition={{duration: 1, ease: "easeOut"}}>
							<FontAwesomeIcon icon={fab.faYoutube}/>
							<span>Youtube Tâm Đức Số</span>
						</motion.a>
					</div>
				</div>

				<motion.div className="w-full mt-4 md:mt-0 md:max-w-[55%] md:ml-4 flex flex-col items-center rounded-lg border-2 border-yellow-500 shadow drop-shadow-2xl shadow-black"
				            ref={ref}
				            initial="hidden"
				            animate={inView ? "visible" : "hidden"}
				            variants={animationVariantsVertical}
				            transition={{duration: 1, ease: "easeOut"}}>

					<MediaPlayer title="Tâm Đức Số" src="https://tds.dcstyle.info/page_first_video.mp4"
					             className="w-full h-full"
					             autoplay muted loop>
						<MediaProvider className="w-full h-full" />
						<DefaultVideoLayout icons={defaultLayoutIcons}/>
					</MediaPlayer>
				</motion.div>
			</div>
		</Page>
	);
}

export default PageFirst;
