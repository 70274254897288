import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {pageMessageId, pageUsername} from "../assets";

const handleClick = () => {
	if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
		window.location.href = `fb-messenger-public://user-thread/${pageMessageId}`;
	} else if (window.navigator.userAgent.match(/Android/i)) {
		window.location.href = `intent://user/${pageMessageId}/#Intent;scheme=fb-messenger;package=com.facebook.orca;end`;
	} else {
		window.location.href = `https://www.messenger.com/t/${pageMessageId}`;
	}
};

const FloatingChatButton = () => {
	return (
		<div className="fixed bottom-4 right-4 z-50">
			<a href="#" onClick={handleClick} target="_blank" rel="noopener noreferrer"
			   className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 px-4 rounded-full shadow-lg floating-chat-button">
				<FontAwesomeIcon icon={fab.faFacebookMessenger} size="2x" className="floating-chat-button-icon" />
			</a>
		</div>
	)
}

export default FloatingChatButton;