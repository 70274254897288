import React from 'react';

import {
	feedback_image_1,
	feedback_image_2,
	feedback_image_3,
	feedback_image_4,
	feedback_image_5,
	feedback_image_6,
	feedback_image_7,
	feedback_image_8,
	feedback_image_9,
	feedback_image_10,

	animationVariantsVertical,
} from "../assets";

import { Swiper, SwiperSlide } from "swiper/react";
import {Pagination, Autoplay, Navigation} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';

import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';

import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Page from "../common/Page";

const FeedBacks = [
	{
		type: 'video',
		message: 'https://tds.dcstyle.info/feedback_video_4.mp4'
	},
	{
		type: 'video',
		message: 'https://tds.dcstyle.info/feedback_video_5.mp4'
	},
	{
		type: 'video',
		message: 'https://tds.dcstyle.info/feedback_video_6.mp4'
	},
	{
		type: 'video',
		message: 'https://tds.dcstyle.info/feedback_video_1.mp4'
	},
	{
		type: 'video',
		message: 'https://tds.dcstyle.info/feedback_video_2.mp4'
	},
	{
		type: 'video',
		message: 'https://tds.dcstyle.info/feedback_video_3.mp4'
	},

	{
		type: 'image',
		message: feedback_image_1
	},
	{
		type: 'image',
		message: feedback_image_2
	},
	{
		type: 'image',
		message: feedback_image_3
	},
	{
		type: 'image',
		message: feedback_image_4
	},
	{
		type: 'image',
		message: feedback_image_5
	},
	{
		type: 'image',
		message: feedback_image_6
	},
	{
		type: 'image',
		message: feedback_image_7
	},
	{
		type: 'image',
		message: feedback_image_8
	},
	{
		type: 'image',
		message: feedback_image_9
	},
	{
		type: 'image',
		message: feedback_image_10
	},
];

const PageFifth = () => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1
	});

	return (
		<Page>
			<motion.h1 className="text-xl font-semibold mb-8 text-center md:text-3xl md:font-bold"
			           ref={ref}
			           initial="hidden"
			           animate={inView ? "visible" : "hidden"}
			           variants={animationVariantsVertical}
			           transition={{duration: 0.5, ease: "easeOut"}}>
				PHẢN HỒI TỪ THÀNH VIÊN ĐÃ THAM GIA TÂM ĐỨC SỐ
			</motion.h1>

			<motion.main className="w-full mx-auto max-md:container md:px-12"
			             ref={ref}
			             initial="hidden"
			             animate={inView ? "visible" : "hidden"}
			             variants={animationVariantsVertical}
			             transition={{duration: 0.5, ease: "easeOut"}}>
				<Swiper
					className="feedback-slider"
					modules={[Pagination, Navigation]}
					spaceBetween={10}
					slidesPerView={1}
					breakpoints={{
						768: {
							slidesPerView: 2,
							spaceBetween: 20,
						},
					}}
					navigation={true}
					pagination={{clickable: true}}
					loop={false}
				>

					{FeedBacks.map((item, key) => {
						return (
							<SwiperSlide className="!h-auto">
								<div className="flex flex-row justify-center h-full max-h-[85vh] md:max-h-[75vh]">
									{(item.type === 'image')
										? (
											<img src={item.message} alt="Feedback"
											     className="w-full h-auto object-contain"/>
										)
										: (
											<div className="flex flex-row justify-center h-full max-h-[85vh] md:max-h-[75vh]">
												<MediaPlayer title="Tâm Đức Số" src={item.message}
												             className="w-full">

													<MediaProvider className="w-full h-full" />
													<DefaultVideoLayout icons={defaultLayoutIcons}/>
												</MediaPlayer>
											</div>
										)
									}
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</motion.main>
		</Page>
	);
}

export default PageFifth;
