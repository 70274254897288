import React from 'react';

import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import {
	animationVariantsHorizontal,
	animationVariantsVertical,
	logic, pageMessageId,
	webRegisterUrl,
	webUrl
} from "../assets";
import Page from "../common/Page";

const handleClick = () => {
	if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
		window.location.href = `fb-messenger-public://user-thread/${pageMessageId}`;
	} else if (window.navigator.userAgent.match(/Android/i)) {
		window.location.href = `intent://user/${pageMessageId}/#Intent;scheme=fb-messenger;package=com.facebook.orca;end`;
	} else {
		window.location.href = `https://www.messenger.com/t/${pageMessageId}`;
	}
};

const PageFourth = () => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0
	});

	return (
		<Page>
			<h1 className="text-xl font-semibold text-center mb-8 md:text-3xl md:font-bold">
				<p className="mb-4">TRANG GHI LÔ ĐỀ UY TÍN, TỈ LỆ ĂN CAO KHI TRÚNG</p>
				<p>(LÔ ĐỀ ĂN 99, KHUYẾN KHÍCH NÊN CÓ TRƯỚC KHI THAM GIA NHÓM)</p>
			</h1>

			<div className="rounded-lg flex flex-row justify-center mb-6">
				<motion.img src={logic}
				            alt="TRANG GHI LÔ ĐỀ UY TÍN, TỈ LỆ ĂN CAO KHI TRÚNG"
				            className="rounded-lg w-full md:max-h-[65vh] md:w-2/3"
				            ref={ref}
				            initial="hidden"
				            animate={inView ? "visible" : "hidden"}
				            variants={animationVariantsVertical}
				            transition={{duration: 0.5, ease: "easeOut"}}
				/>
			</div>

			<motion.div className="bg-black bg-opacity-35 p-4 rounded-lg mb-8 max-w-4xl text-center text-yellow-500 font-bold text-[16px] md:text-3xl"
			            ref={ref}
			            initial="hidden"
			            animate={inView ? "visible" : "hidden"}
			            variants={animationVariantsVertical}
			            transition={{duration: 0.5, ease: "easeOut"}}>
				<p className="mb-6">
					Thời đại 4.0 rồi, đừng để chủ lô thịt phần chênh lệch lớn
					mà đáng lẽ mọi người được hưởng khi trúng số.
				</p>
				<p>
					Vốn rất quan trọng khi chơi số, vậy nên biết cách chơi trang
					để được ăn cao nhất khi trúng là 1 lợi thế.
				</p>
			</motion.div>

			<div className="flex flex-row justify-center gap-x-4 md:text-2xl">
				<motion.a href={webRegisterUrl}
				          target="_blank"
				          rel="noreferrer noppener"
				          className="text-sm text-center bg-yellow-500 text-black font-semibold py-2 px-8 rounded-md hover:bg-yellow-600 transition duration-300 md:text-2xl md:rounded-full"
				          ref={ref}
				          initial="hidden"
				          animate={inView ? "visible" : "hidden"}
				          variants={animationVariantsHorizontal}
				          transition={{duration: 0.5, ease: "easeOut"}}>

					Đăng ký miễn phí
				</motion.a>

				<motion.a href="#"
				          onClick={handleClick}
				          target="_blank"
				          rel="noreferrer noppener"
				          className="text-sm text-center bg-blue-700 text-white font-semibold py-2 px-8 rounded-md hover:bg-blue-900 transition duration-300 md:text-2xl md:rounded-full"
				          ref={ref}
				          initial="hidden"
				          animate={inView ? "visible" : "hidden"}
				          variants={animationVariantsHorizontal}
				          transition={{duration: 0.5, ease: "easeOut"}}>
					Hỗ trợ đăng ký
				</motion.a>
			</div>
		</Page>
	);
}

export default PageFourth;
