import React from 'react';

import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import {animationVariants, animationVariantsHorizontal, animationVariantsVertical, person} from "../assets";
import Page from "../common/Page";

const Sections = [
	{
		name: 'CỘNG ĐỒNG DÀNH CHO AI',
		children: [
			{
				icon: faCheckCircle,
				segments: [
					{
						text: 'Những bạn đang khó khăn trong bộ môn lô số, đang cần sự giúp đỡ từ cộng đồng uy tín'
					}
				]
			},
			{
				icon: faCheckCircle,
				segments: [
					{
						text: 'Loạn cầu, bí số tâm lý bất ổn càng đánh to càng thua'
					}
				]
			},
			{
				icon: faCheckCircle,
				segments: [
					{
						text: 'Những người muốn cơm gạo hàng ngày, kiếm thêm thu nhập tay trái từ bộ môn lô số'
					}
				]
			}
		]
	},

	{
		name: 'LỢI ÍCH TỪ CỘNG ĐỒNG',
		children: [
			{
				icon: faCheckCircle,
				segments: [
					{
						text: 'Được tham khảo số miễn phí hàng ngày, được soi trực tiếp bởi chú Tâm và các ae có ' +
							'kinh nghiệm lâu năm trong bộ môn lô số'
					}
				]
			},
			{
				icon: faCheckCircle,
				segments: [
					{
						text: 'Thay đổi tư duy về chơi số, hoc được cách chơi bài bản, kiếm được tiền hàng tháng của Tâm Đức Số'
					}
				]
			},
			{
				icon: faCheckCircle,
				segments: [
					{
						text: 'Được tham gia các sự kiện thưởng tiền mặt của Tâm Đức Số'
					}
				]
			},
			{
				icon: faCheckCircle,
				segments: [
					{
						text: 'Hỗ trợ chỉ dẫn các trang ghi số online uy tín lâu năm được Tâm Đức Số tin dùng, khi trúng lô đề ' +
							'ăn cao hơn so với chơi ở ngoài chủ lô'
					}
				]
			}
		]
	},

	{
		name: 'ANH EM ĐƯỢC GÌ',
		children: [
			{
				icon: faCheckCircle,
				segments: [
					{ text: 'Một điều chắc chắn rằng khi tham gia cộng đồng Tâm Đức Số mọi người sẽ được lợi rất nhiều từ ' },
					{ text: 'TIỀN tới PHƯƠNG PHÁP CHƠI SỐ', highlighted: true },
					{ text: ' so với việc chơi một mình hay tham gia các nhóm số lừa đảo, kém uy tín khác' }
				]
			}
		]
	}
];

const SectionItem = ({name, children}) => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1
	});

	return (
		<motion.section className="mb-8 bg-black bg-opacity-80 py-2 px-4 rounded-md"
		                ref={ref}
		                initial="hidden"
		                animate={inView ? "visible" : "hidden"}
		                variants={animationVariantsHorizontal}
		                transition={{duration: 1, ease: "easeOut"}}>
			<h2 className="block text-[16px] font-semibold text-yellow-500 text-shadow shadow-black md:text-[20px] md:font-bold">{name}</h2>
			<ul className="mt-2 list-disc list-inside">
				{children.map((item, key) => {
					return <ChildItem icon={item.icon} segments={item.segments} key={key} />
				})}
			</ul>
		</motion.section>
	);
}

const ChildItem = ({icon, segments}) => {
	return (
		<li className="mt-2 flex items-start">
			<FontAwesomeIcon icon={icon} className="mt-2 mr-2 text-yellow-500" />
			<p className="text-md md:text-[19px]">
				{segments.map((segment, index) =>
						segment.highlighted ? (
							<span key={index} className="font-bold text-yellow-500">
				              {segment.text}
				            </span>
						) : (
							<span key={index}>{segment.text}</span>
						)
				)}
			</p>
		</li>
	);
}

const PageSecond = () => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1
	});

	return (
		<Page>
			<motion.h1 className="text-xl font-semibold mb-4 text-yellow-500 text-center md:text-3xl md:font-bold"
			           ref={ref}
			           initial="hidden"
			           animate={inView ? "visible" : "hidden"}
			           variants={animationVariantsVertical}
			           transition={{duration: 1, ease: "easeOut"}}>
				Nhóm Tâm Đức Số có gì?
			</motion.h1>

			<div className="w-full max-w-screen-lg flex flex-col items-center px-8 md:flex-row md:px-0">
				<div className="md:w-2/3 md:pr-12">
					{Sections.map((section, key) => {
						return <SectionItem name={section.name} children={section.children} key={key}/>
					})}
				</div>

				<div className="hidden justify-center ml-4 mt-8 flex-col md:w-1/3 md:mt-0 md:flex">
					<img src={person} alt="Nhóm Tâm Đức Số có gì?" className="max-w-full h-full"/>

					<div className="text-center font-bold mt-4">
						<p className="text-4xl text-yellow-500">Ông Ngô Minh Tâm</p>
						<p>Người sáng lập cộng đồng Tâm Đức Số</p>
					</div>
				</div>
			</div>
		</Page>
	);
}

export default PageSecond;
