// Images
import logo from './/assets/images/logo.png';
import background from './/assets/images/background.png';
import background_mobile from './/assets/images/background_mobile.jpg';
import person from './/assets/images/person.png';
import benefitBg from './/assets/images/benefitBg.png';

import feedback_messenger from './/assets/images/feedback_messenger.png';
import feedback_image_1 from './/assets/images/feedback_image_1.png';
import feedback_image_2 from './/assets/images/feedback_image_2.png';
import feedback_image_3 from './/assets/images/feedback_image_3.png';
import feedback_image_4 from './/assets/images/feedback_image_4.png';
import feedback_image_5 from './/assets/images/feedback_image_5.png';
import feedback_image_6 from './/assets/images/feedback_image_6.png';
import feedback_image_7 from './/assets/images/feedback_image_7.png';
import feedback_image_8 from './/assets/images/feedback_image_8.png';
import feedback_image_9 from './/assets/images/feedback_image_9.png';
import feedback_image_10 from './/assets/images/feedback_image_10.png';

import logic from './/assets/images/logic.png';

import stats_image_1 from './/assets/images/stats_image_1.jpg';
import stats_image_2 from './/assets/images/stats_image_2.jpg';
import stats_image_3 from './/assets/images/stats_image_3.jpg';
import stats_image_4 from './/assets/images/stats_image_4.png';
import stats_image_5 from './/assets/images/stats_image_5.png';

// Videos

export const webUrl = 'https://www.vb3969c.net/Home/Index';
export const webRegisterUrl = 'https://dv353.ku3933.net';
export const pageUsername = '164119813448473';
export const pageMessageId = '61553391263450';

export const animationVariantsHorizontal = {
	hidden: { opacity: 0, x: -200 },
	visible: { opacity: 1, x: 0 }
};

export const animationVariantsVertical = {
	hidden: { opacity: 0, y: -200 },
	visible: { opacity: 1, y: 0 }
};

export {
	// Images
	logo,
	background,
	background_mobile,
	person,
	benefitBg,

	feedback_messenger,
	feedback_image_1,
	feedback_image_2,
	feedback_image_3,
	feedback_image_4,
	feedback_image_5,
	feedback_image_6,
	feedback_image_7,
	feedback_image_8,
	feedback_image_9,
	feedback_image_10,

	logic,

	stats_image_1,
	stats_image_2,
	stats_image_3,
	stats_image_4,
	stats_image_5,

	// Videos
};