import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRight, faDotCircle} from "@fortawesome/free-solid-svg-icons";

import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { Swiper, SwiperSlide } from "swiper/react";
import {Pagination, Autoplay, Navigation} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {
	animationVariantsHorizontal,
	animationVariantsVertical,
	logo, pageMessageId,
} from "../assets";
import Page from "../common/Page";

const handleClick = () => {
	if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
		window.location.href = `fb-messenger-public://user-thread/${pageMessageId}`;
	} else if (window.navigator.userAgent.match(/Android/i)) {
		window.location.href = `intent://user/${pageMessageId}/#Intent;scheme=fb-messenger;package=com.facebook.orca;end`;
	} else {
		window.location.href = `https://www.messenger.com/t/${pageMessageId}`;
	}
};

const Benefits = [
	{
		title: 'THAM KHẢO SỐ TỈ LỆ NỔ TỐT TỪ TÂM ĐỨC SỐ',
		contents: [
			'Vào 17h hàng ngày sẽ được tham khảo số miễn phí của chú Tâm và ae chơi số lâu năm soi trực tiếp hàng ngày'
		]
	},

	{
		title: 'CẤP LỘC CHÀO MỪNG',
		contents: [
			'Thành viên của Tâm Đức Số sẽ được nhận khoản tiền lộc chào mừng khi mới tham gia nhóm'
		]
	},
	{
		title: 'HỖ TRỢ VỐN',
		contents: [
			'Khi vào nhóm Tâm Đức Số NẾU THUA, thành viên sẽ được hỗ trợ một khoản tiền thua trong 3 ngày đầu vào nhóm'
		]
	},

	{
		title: 'HỌC CÁCH SOI SỐ',
		contents: [
			'Được đội ngũ cao thủ top 1, dày dặn kinh nghiệm chia sẻ cầu kèo, cách chia vốn, ' +
			'phương pháp chơi tháng nào cũng ra tiền của Tâm Đức Số'
		]
	},

	{
		title: 'THAM GIA SỰ KIỆN',
		contents: [
			'Được tham gia tất cả các sự kiện của Tâm Đức Số với mức thưởng tiền mặt giá trị'
		]
	},
];

const BenefitItem = ({title, contents, isInSlide}) => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1
	});

	return (
		<motion.div className={`bg-white bg-[url('.//assets/images/benefitBg.png')] bg-no-repeat bg-[length:150px_200px] bg-right-bottom text-black rounded-2xl shadow-lg shadow-black flex flex-col items-center sm:h-full ${isInSlide ? 'w-full' : ''}`}
		            ref={ref}
		            initial="hidden"
		            animate={inView ? "visible" : "hidden"}
		            variants={animationVariantsHorizontal}
		            transition={{duration: .3, ease: "easeOut"}}>

			<div className="w-full h-auto flex flex-col items-center justify-center bg-gray-800 py-3 mb-4 rounded-t-2xl">
				<img src={logo} alt={title} className="w-auto h-32"/>
			</div>

			<div className="w-full px-4 pb-6 md:h-full md:flex md:flex-col">
				<h2 className="text-md font-bold mb-2 text-ellipsis overflow-x-hidden whitespace-nowrap">{title}</h2>

				<ul className="text-left mb-4">
					{contents.map((content, i) => (
						<li key={i} className="mt-2 line-clamp-3">
							<FontAwesomeIcon icon={faDotCircle} className="mr-2"/>
							<span>{content}</span>
						</li>
					))}
				</ul>

				<div className="flex w-full justify-center md:block md:w-auto md:mt-auto md:max-w-[180px]">
					<a href="#" onClick={handleClick} target="_blank" rel="noopener noreferrer" className="accept-offer-button inline-block bg-yellow-500 benefit-button text-gray-800 rounded-md px-6 py-2 font-bold border border-black">
						<span>Nhận ưu đãi</span>
						<FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
					</a>
				</div>
			</div>
		</motion.div>
	);
}

const PageThird = () => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1
	});

	return (
		<Page>
			<motion.h1 className="text-xl font-semibold mb-8 text-center md:text-3xl md:font-bold"
			           ref={ref}
			           initial="hidden"
			           animate={inView ? "visible" : "hidden"}
			           variants={animationVariantsVertical}
			           transition={{duration: 1, ease: "easeOut"}}>
				QUYỀN LỢI CỦA THÀNH VIÊN KHI THAM GIA
			</motion.h1>

			<div className="w-full mx-auto container">
				<div className="block">
					<div className="flex flex-row flex-wrap justify-center max-md:gap-y-8 md:-mx-4 md:gap-4 lg:-mx-8 lg:gap-8">
						{Benefits.map((benefit, index) => {
							return <div key={index} className="w-full hover:z-10 md:w-1/2 lg:w-1/4 transform transition-transform duration-500 hover:scale-110">
								<BenefitItem title={benefit.title} contents={benefit.contents} isInSlide={true} />
							</div>
								;
						})}
					</div>
				</div>

				<div className="hidden">
					<Swiper
						className="benefit-slider md:!py-6"
						modules={[Navigation, Pagination, Autoplay]}
						spaceBetween={10}
						slidesPerView={1}
						breakpoints={{
							768: {
								slidesPerView: 3,
								spaceBetween: 20,
							}
						}}
						pagination={{clickable: true}}
						navigation={true}
						loop={true}
						autoplay={{
							delay: 3500,
							disableOnInteraction: true
						}}
					>
						{Benefits.map((benefit, key) => {
							return <SwiperSlide key={key} className="hover:z-10">
								<div className="flex flex-row justify-center">
									<BenefitItem title={benefit.title} contents={benefit.contents} isInSlide={true}/>
								</div>
							</SwiperSlide>
								;
						})}
					</Swiper>
				</div>
			</div>
		</Page>
	);
}

export default PageThird;
